(function (factory) {
  if (typeof module === "object" && typeof module.exports === "object") {
    var v = factory(require, exports);
    if (v !== undefined) module.exports = v;
  } else if (typeof define === "function" && define.amd) {
    define(["require", "exports"], factory);
  }
})(function (require, exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.matches = exports.extract = exports.replace = void 0;
  var token = '[\\w-]*';
  var replace = function (pathPattern, data, _a) {
    var _b = _a === void 0 ? {} : _a,
      _c = _b.prefix,
      prefix = _c === void 0 ? ':' : _c,
      _d = _b.delimiter,
      delimiter = _d === void 0 ? '/' : _d;
    if (typeof data === 'string') {
      return pathPattern.replace(getMatchRegex(prefix), data);
    }
    var _e = getMatches(pathPattern, prefix, delimiter),
      pathFragments = _e.pathFragments,
      isAMatch = _e.isAMatch;
    return pathFragments.map(function (fragment) {
      return isAMatch(fragment) ? getDataByFragment(data, fragment, prefix) : fragment;
    }).join(delimiter);
  };
  exports.replace = replace;
  var extract = function (pathPattern, pathInstance, _a) {
    var _b = _a === void 0 ? {} : _a,
      _c = _b.prefix,
      prefix = _c === void 0 ? ':' : _c,
      _d = _b.delimiter,
      delimiter = _d === void 0 ? '/' : _d;
    var pathWithoutPrefixRegex = new RegExp(pathPattern.replace(getMatchRegex(prefix), token));
    if (!pathWithoutPrefixRegex.test(pathInstance)) {
      return {};
    }
    var _e = getMatches(pathPattern, prefix, delimiter),
      pathFragments = _e.pathFragments,
      isAMatch = _e.isAMatch;
    return pathInstance.split(delimiter).reduce(function (data, replacedFragment, index) {
      var originalFragment = pathFragments[index];
      if (isAMatch(originalFragment)) {
        data[removePrefix(originalFragment, prefix)] = replacedFragment;
      }
      return data;
    }, {});
  };
  exports.extract = extract;
  var matches = function (pathPattern, pathInstance) {
    var data = (0, exports.extract)(pathPattern, pathInstance);
    var hasData = Object.keys(data).length > 0;
    var instanceMatchesPattern = (0, exports.replace)(pathPattern, data) === pathInstance;
    return hasData && instanceMatchesPattern;
  };
  exports.matches = matches;
  var getMatchRegex = function (prefix) {
    return new RegExp("\\".concat(prefix).concat(token), 'g');
  };
  var getMatches = function (path, prefix, delimiter) {
    var pathFragments = path.split(delimiter);
    var pathMatches = path.match(getMatchRegex(prefix)) || [];
    var isAMatch = function (fragment) {
      return pathMatches.indexOf(fragment) !== -1;
    };
    return {
      pathFragments: pathFragments,
      isAMatch: isAMatch
    };
  };
  var getDataByFragment = function (data, fragment, prefix) {
    return data[fragment.replace(prefix, '')] || '';
  };
  var removePrefix = function (fragment, prefix) {
    return fragment.replace(prefix, '');
  };
});