<div
    class="pointer-events-auto flex max-h-full w-full flex-col overflow-clip rounded-xl bg-white dark:bg-gray-900 sm:w-72"
>
    <sz-overlay-title-bar
        [isCollapsed]="isCollapsed"
        (toggleCollapsed)="isCollapsed = !isCollapsed"
    >
        <sz-input
            class="grow"
            icon="magnify"
            [formControl]="deviceFilterControl"
            [small]="true"
            (click)="$event.stopPropagation()"
        />
    </sz-overlay-title-bar>
    <div
        class="flex w-full grow flex-col gap-2 overflow-y-auto"
        [class.hidden]="isCollapsed"
    >
        <ng-container *ngFor="let sectionId of sectionIds; let i = index">
            <div
                *ngIf="(sections[i] | searchDevices: deviceFilter).length > 0"
                class="flex flex-col"
            >
                <div
                    class="flex items-center gap-2 pl-4 pr-2"
                    [ngClass]="isSectionCollapsed(sectionId) ? 'last:mb-2' : ''"
                >
                    <mat-icon
                        class="!h-4 !w-4"
                        [style.color]="sectionDotColor(sectionId)"
                        [svgIcon]="i === 0 ? 'pin' : 'circle'"
                    ></mat-icon>
                    <span class="text-sm opacity-80 dark:opacity-70">
                        {{ sectionTitle(sectionId) | translate }}
                    </span>
                    <span class="badge badge-secondary badge-outline badge-sm">
                        {{ (sections[i] | searchDevices: deviceFilter).length }}
                    </span>
                    <sz-icon-button
                        class="ml-auto"
                        [icon]="
                            isSectionCollapsed(sectionId)
                                ? 'chevron-down'
                                : 'chevron-up'
                        "
                        size="small"
                        (click)="toggleSectionCollapse(sectionId)"
                    />
                </div>
                <ng-container *ngIf="!isSectionCollapsed(sectionId)">
                    <button
                        *ngFor="
                            let device of sections[i]
                                | searchDevices: deviceFilter
                        "
                        class="mx-1 my-0.5 rounded-xl p-1 outline-none last:mb-1"
                        (click)="openDevice.emit(device.id)"
                        [ngClass]="
                            selectedDeviceId === device.id
                                ? 'bg-primary-400 text-white dark:bg-primary-700'
                                : 'hover:bg-gray-700/20 focus:bg-gray-700/20 dark:hover:bg-gray-400/20 dark:focus:bg-gray-400/20'
                        "
                    >
                        <div class="flex flex-row items-center gap-2">
                            <sz-image
                                alt="model_editor.model_image"
                                class="h-10 w-10 min-w-[40px]"
                                classList="rounded-full"
                                [src]="
                                    productImagePaths[device.modelId] ??
                                    defaultPath
                                "
                            />
                            <div class="flex grow flex-col gap-0.5 truncate">
                                <div class="text-start text-base leading-none">
                                    <ng-container
                                        *ngIf="
                                            showUserIdentifier;
                                            else identifier
                                        "
                                    >
                                        {{
                                            device.userIdentifier ??
                                                device.identifier
                                        }}
                                    </ng-container>
                                    <ng-template #identifier>
                                        {{ device.identifier }}
                                    </ng-template>
                                </div>
                                <!-- put more things here in the future -->
                                <div class="flex flex-row gap-1">
                                    <!-- Only pinned devices need a dot to display
                                     their online status. Non-pinned devices
                                     will be under their appropriate section
                                     header. -->
                                    <mat-icon
                                        *ngIf="
                                            pinnedDeviceOrder.includes(
                                                device.id
                                            )
                                        "
                                        class="!h-4 !w-4"
                                        [style.color]="device.symbol?.fillColor"
                                        svgIcon="circle"
                                        [matTooltip]="
                                            sectionTitle(device.onlineCategory)
                                                | translate
                                        "
                                    ></mat-icon>
                                    <mat-icon
                                        *ngIf="noLocation(device)"
                                        class="!h-4 !w-4"
                                        [matTooltip]="
                                            'device.location.none' | translate
                                        "
                                        svgIcon="map-marker-off"
                                    ></mat-icon>
                                </div>
                            </div>
                        </div>
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
