<div
    class="pointer-events-auto flex h-fit w-full flex-col overflow-clip rounded-xl bg-white dark:bg-gray-900 sm:w-72 lg:w-[400px]"
    [ngClass]="isCollapsed ? 'hidden sm:block' : 'block'"
>
    <sz-overlay-title-bar
        [isCollapsed]="isCollapsed"
        (toggleCollapsed)="isCollapsed = !isCollapsed"
    >
        <div class="flex grow flex-row items-center gap-3">
            <div *ngIf="current" class="badge badge-accent badge-sm ml-1">
                {{ count }}
            </div>
            <span class="grow text-start text-sm opacity-80 dark:opacity-70">
                {{ 'announcement.plural' | translate }}
            </span>
        </div>
    </sz-overlay-title-bar>
    <div class="flex w-full grow flex-col" [class.hidden]="isCollapsed">
        <div class="max-h-[100px] overflow-y-auto px-3">
            {{ currentMessage }}
        </div>
        <div class="flex flex-row items-center gap-1 p-2">
            <div class="grow px-2 text-sm opacity-80 dark:opacity-70">
                {{ current?.timestamp | date: dateTimeService.dateFormat }}
            </div>
            @if (current) {
                @if (current.docsPath) {
                    <sz-icon-button
                        icon="link"
                        size="small"
                        [title]="'announcement.check_docs' | translate"
                        (click)="openDocs()"
                    />
                }
                <sz-icon-button
                    icon="check"
                    color="success"
                    size="small"
                    [title]="'announcement.mark_as_read' | translate"
                    (click)="read()"
                />
            }
            <sz-icon-button
                icon="message-text"
                color="primary"
                size="small"
                [title]="'help.send_feedback' | translate"
                (click)="sendFeedback()"
            />
        </div>
    </div>
</div>
