import { Injectable } from '@angular/core';
import * as pathern from 'pathern';
import { ApiService } from '../api/api.service';
import { RouteMetadata, defaultRouteMetadata, routesMetadata } from './routes';
import { CustomerRole } from '../constants';

@Injectable({ providedIn: 'root' })
export class RouteMetadataService {
    private routesMetadata: RouteMetadata[] = routesMetadata;

    constructor(private api: ApiService) {}

    private getRouteMetadata(path: string): RouteMetadata {
        // Remove hash and query parameters
        path = path.split('#')[0].split('?')[0];
        // Find matching metadata
        const metadata = this.routesMetadata.find(
            ({ route }) => route == path || pathern.matches(route, path),
        );
        return metadata ?? defaultRouteMetadata;
    }

    getHasRole(path): CustomerRole {
        return this.getRouteMetadata(path).hasRole;
    }

    getIcon(path): string {
        return this.getRouteMetadata(path).icon;
    }

    async getTitle(path): Promise<string> {
        const metadata = this.getRouteMetadata(path);
        const { id } = pathern.extract(metadata.route, path);
        if (id && metadata.api) {
            const preview = await this.api[metadata.api].loadPreview(
                parseId(id),
            );
            return preview.title;
        } else {
            return metadata.title;
        }
    }
}

const parseId = (id: string): number => {
    // Remove query params if exists
    if (id.match('\\?')) {
        return +id.split('?')[0];
    }
    // Remove fragment if exists
    if (id.match('\\#')) {
        return +id.split('#')[0];
    }
    return +id;
};
