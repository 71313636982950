import { Injectable } from '@angular/core';
import { RouterIcon } from 'src/app/root/model/root.model';
import { getUserId } from 'src/app/shared/auth-utils';
import { Pin } from 'src/app/shared/models/pin';
import { ApiService } from '../api/api.service';
import { DateTimeService } from '../date-time.service';
import { RouteMetadataService } from '../route-metadata/route-metadata.service';

export interface Shortcut {
    name: string;
    url: string;
    icon: string;
    pinId?: number;
}

@Injectable()
export class ShortcutsService {
    pins: Pin[];
    pinned: Shortcut[];
    topViewed: Shortcut[];
    iconMap = {
        alert: RouterIcon.ALERTS,
        dashboard: 'collage',
        device: RouterIcon.DEVICES,
        'geo-fence': RouterIcon.GEO_FENCING,
        model: RouterIcon.MODELS,
        organization: RouterIcon.ORGANIZATIONS,
        report: RouterIcon.REPORTS,
        widget: RouterIcon.CUSTOM_WIDGETS,
    };

    constructor(
        private api: ApiService,
        private dateTimeService: DateTimeService,
        private routeMetadata: RouteMetadataService,
    ) {
        // Get the pins
        this.api.pins
            .listen({ sort: 'order', sortOrder: 'ASC' })
            .subscribe((pins) => {
                this.pins = pins;
                this.pinned = this.pins
                    .filter((p) => p.name !== null)
                    .map(({ id, itemId, type, name }) => ({
                        url: '/' + type + 's/' + itemId,
                        icon: this.iconMap[type],
                        name,
                        pinId: id,
                    }));
                // TODO: add automatically-generated shortcuts
            });
        // Get the top 5 viewed pages
        const timezoneOffset = this.dateTimeService.isUtcTime
            ? 0
            : new Date().getTimezoneOffset();
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        this.api.pageViews
            .getStats({
                groupBy: 'path',
                timezoneOffset,
                userId: getUserId(),
                limit: 5,
                startDate,
                includeTopLevelRoutes: false,
            })
            .then(async (topViewed) => {
                this.topViewed = await Promise.all(
                    topViewed.map(async ({ value }) => ({
                        name: await this.routeMetadata.getTitle(
                            value as string,
                        ),
                        url: value as string,
                        icon: this.routeMetadata.getIcon(value as string),
                    })),
                );
            });
    }
}
