import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Shortcut } from 'src/app/services/shortcuts/shortcuts.service';

@Component({
    selector: 'app-shortcut-item',
    templateUrl: 'shortcut-item.component.html',
})
export class ShortcutItemComponent {
    @Input() shortcut: Shortcut;
    @Input() isDraggable = false;
    @Output() removePin = new EventEmitter();
}
