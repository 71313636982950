import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { isMobile } from 'src/app/shared/screen-size-utils';
import { LiveMapDevice } from 'src/app/shared/models/device';
import { AnnouncementsComponent } from '../announcements/announcements.component';
import { HomeDeviceListComponent } from '../home-device-list/home-device-list.component';
import { ShortcutsComponent } from '../shortcuts/shortcuts.component';

@Component({
    selector: 'app-home-overlays',
    templateUrl: 'home-overlays.component.html',
    styles: [':host { position: absolute; inset: 0; pointer-events: none; }'],
})
export class HomeOverlaysComponent implements OnInit, OnChanges {
    @Input({ required: true }) selectedDeviceId: number;
    @Input({ required: true }) devices: LiveMapDevice[];

    @Output() selectedDeviceIdChanged = new EventEmitter<number>();

    @ViewChild('announcementsOverlay', { static: true })
    announcementsOverlay: AnnouncementsComponent;
    @ViewChild('devicesOverlay', { static: true })
    devicesOverlay: HomeDeviceListComponent;
    @ViewChild('shortcutsOverlay', { static: true })
    shortcutsOverlay: ShortcutsComponent;
    // A mapping of overlay titles to overlay components
    overlays: Record<string, { isCollapsed: boolean }> = {};

    isMobile = false;
    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    get selectedDevice() {
        return this.devices.find(({ id }) => this.selectedDeviceId === id);
    }

    get overlayKeys(): string[] {
        return Object.keys(this.overlays);
    }

    async ngOnInit() {
        this.isMobile = isMobile();
        this.overlays['announcement.plural'] = this.announcementsOverlay;
        this.overlays['device.plural'] = this.devicesOverlay;
        this.overlays['common.shortcuts'] = this.shortcutsOverlay;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedDeviceId) {
            if (this.isMobile && this.selectedDeviceId !== null) {
                for (const overlay of Object.values(this.overlays)) {
                    overlay.isCollapsed = true;
                }
            }
        }
    }

    toggleOverlay(key) {
        const collapsed = !this.overlays[key].isCollapsed;
        this.overlays[key].isCollapsed = collapsed;
        if (this.isMobile && !collapsed) {
            this.selectedDeviceIdChanged.next(null);
        }
    }
}
