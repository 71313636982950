<div
    class="flex max-h-full flex-col gap-1 p-1 pb-6 sm:flex-row sm:justify-between sm:gap-3 sm:p-3 sm:pb-8"
>
    <div
        class="pointer-events-auto flex w-full flex-row items-center justify-center gap-1 rounded-lg sm:hidden"
    >
        <button
            *ngFor="let key of overlayKeys"
            class="flex items-center justify-center rounded-lg p-3"
            [ngClass]="
                overlays[key].isCollapsed
                    ? 'bg-white dark:bg-gray-900'
                    : 'text-on-primary-500 bg-primary-500'
            "
            (click)="toggleOverlay(key)"
        >
            <span class="text-sm">
                {{ key | translate }}
            </span>
        </button>
    </div>
    <!-- We only need a gap on mobile when both overlays are open -->
    <div
        class="min-h-0 flex-col gap-1 sm:gap-3 lg:flex-row-reverse"
        [ngClass]="
            announcementsOverlay.isCollapsed && devicesOverlay.isCollapsed
                ? 'hidden sm:flex'
                : 'flex'
        "
    >
        <app-announcements
            #announcementsOverlay
            [ngClass]="
                announcementsOverlay.isCollapsed ? 'hidden sm:block' : 'block'
            "
        />
        <!-- The flex class is important for making the height work correctly on
             mobile, for some reason. -->
        <app-home-device-list
            #devicesOverlay
            class="min-h-0"
            [ngClass]="
                devicesOverlay.isCollapsed
                    ? 'hidden max-h-[42px] sm:flex'
                    : 'flex h-fit max-sm:grow lg:max-h-full'
            "
            [devices]="devices"
            [selectedDeviceId]="selectedDeviceId"
            (openDevice)="selectedDeviceIdChanged.emit($event)"
        />
    </div>
    <div
        class="flex-col gap-1 sm:gap-3"
        [ngClass]="
            shortcutsOverlay.isCollapsed && !selectedDevice
                ? 'hidden sm:flex'
                : 'flex'
        "
    >
        <app-shortcuts
            #shortcutsOverlay
            class="min-h-0 overflow-clip"
            [ngClass]="
                shortcutsOverlay.isCollapsed ? 'hidden sm:block' : 'block'
            "
        />
        <app-device-info
            *ngIf="selectedDevice"
            [device]="selectedDevice"
            (unselectDevice)="selectedDeviceIdChanged.emit(null)"
        />
    </div>
</div>
