<div
    class="pointer-events-auto flex max-h-[160px] h-full w-full flex-col overflow-clip rounded-xl bg-white dark:bg-gray-900 sm:max-h-[440px] sm:w-72"
    [style.min-height]="isCollapsed ? '40px' : '120px'"
>
    <sz-overlay-title-bar
        [isCollapsed]="isCollapsed"
        (toggleCollapsed)="isCollapsed = !isCollapsed"
    >
        <span class="grow text-start text-sm opacity-80 dark:opacity-70">
            {{ 'common.shortcuts' | translate }}
        </span>
    </sz-overlay-title-bar>
    <div
        class="flex w-full grow flex-col overflow-y-auto"
        [class.hidden]="isCollapsed"
    >
        <div class="mx-2 grow flex justify-between">
            <div class="flex gap-2 text-start items-center">
                <mat-icon
                    class="!h-4 !w-4"
                    [style.color]="'goldenrod'"
                    svgIcon="pin"
                />
                <span class="text-sm opacity-80 dark:opacity-70">{{
                    'pin.section.title' | translate
                }}</span>
            </div>
            <sz-icon-button
                [color]="isDraggable ? 'success' : ''"
                [icon]="isDraggable ? 'check' : 'swap-vertical'"
                size="medium"
                (click)="toggleDraggable()"
                [title]="
                    (isDraggable ? 'btn.confirm' : 'common.reorder') | translate
                "
            />
        </div>
        @if (!isDraggable) {
            <div>
                @for (shortcut of shortcuts.pinned; track shortcut.url) {
                    <app-shortcut-item
                        [shortcut]="shortcut"
                        (removePin)="removePin(shortcut.pinId)"
                    />
                }
            </div>
        } @else {
            <div cdkDropList (cdkDropListDropped)="drop($event)">
                @for (shortcut of shortcuts.pinned; track shortcut.url) {
                    <div
                        class="opacity-100 bg-white rounded-lg dark:bg-gray-900"
                        cdkDrag
                    >
                        <div
                            class="h-0.5 bg-secondary"
                            *cdkDragPlaceholder
                        ></div>
                        <app-shortcut-item
                            [isDraggable]="isDraggable"
                            [shortcut]="shortcut"
                        />
                    </div>
                }
            </div>
        }
        <div class="mt-4 mx-2 grow flex items-center gap-2 text-start">
            <mat-icon
                class="!h-4 !w-4"
                [style.color]="'goldenrod'"
                svgIcon="sort-variant"
            />
            <span class="text-sm opacity-80 dark:opacity-70">{{
                'common.top_viewed' | translate
            }}</span>
        </div>
        @if (isIdentifiedTrackingAllowed) {
            @for (shortcut of shortcuts.topViewed; track shortcut.url) {
                <app-shortcut-item [shortcut]="shortcut" />
            }
        } @else {
            <div class="m-3 grow text-start">
                {{ 'settings.identified_tracking.off' | translate }}
            </div>
            <a
                class="m-3 mt-0 flex flex-row h-10 items-center justify-center gap-3 rounded-lg bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                routerLink="/settings"
            >
                <mat-icon svgIcon="cog" />
                <span>
                    {{ 'settings.go_to' | translate }}
                </span>
            </a>
        }
    </div>
</div>
