import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ShortcutsService } from 'src/app/services/shortcuts/shortcuts.service';
import { SettingsService } from 'src/app/services/user/settings.service';
import { Pin } from 'src/app/shared/models/pin';

@Component({
    selector: 'app-shortcuts',
    templateUrl: 'shortcuts.component.html',
})
export class ShortcutsComponent {
    isCollapsed = false;
    isDraggable = false;

    get isIdentifiedTrackingAllowed() {
        return this.settings.get('account.identified-tracking');
    }

    constructor(
        private api: ApiService,
        private settings: SettingsService,
        public shortcuts: ShortcutsService,
    ) {}

    removePin(pinId: number) {
        this.api.pins.delete([pinId]);
    }

    drop(event: CdkDragDrop<Pin[]>) {
        moveItemInArray(
            this.shortcuts.pinned,
            event.previousIndex,
            event.currentIndex,
        );
    }

    toggleDraggable() {
        this.isDraggable = !this.isDraggable;
        if (!this.isDraggable) {
            // Get pins ids in order
            const pinIds = this.shortcuts.pinned.map(({ pinId }) => pinId);
            this.api.pins.reorder(pinIds);
        }
    }
}
